import React from 'react';
import ParticleAnimation from 'react-particle-animation'

export default class Particles extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.numParticles = (window.innerHeight / 20) + (window.innerWidth / 10)
    }

    static getDerivedStateFromError(error) {
        console.error(error);
        return { hasError: true };
    }

    render() {
        if (!this.state.hasError) {
            // You can render any custom fallback UI
            return <ParticleAnimation
                numParticles={this.numParticles}
                background={{ r: 17, g: 45, b: 78, a: 255 }}
                color={{ r: 77, g: 118, b: 161, a: 255 }}
                particleSpeed={0.25}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: "-1"
                }}
            />
        }

        return <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: "-1",
            backgroundColor: "#102e4e"
        }} />
    }
}