import React from 'react';
import logo_symbol from '../nilsonium_logo_symbol.png';

function MainLogo() {
    return (
        <>
            <div className="d-xs-block d-sm-none">
                <img src={logo_symbol} className="App-logo-symbol-mobile" alt="logo" />
            </div>
            <div className="d-none d-sm-block">
                <img src={logo_symbol} className="App-logo-symbol-desktop" alt="logo" />
            </div>
            <div>
                <h1 className='h4'>Front end | Back end | Cloud solutions</h1>
                <h2 className='h6'>Freelancing @ 125$/hr</h2>
            </div>
        </>
    );
}

export default MainLogo;