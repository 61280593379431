import React from 'react';
import { SocialIcon } from 'react-social-icons';

function Social() {
    return (
        <div className="row">
            <div className="col-xs-4 Social-icon">
                <SocialIcon url="https://www.linkedin.com/in/olni/" network="linkedin" bgColor="#102e4e" fgColor="#e8eef2" target="_blank"/>
            </div>
            <div className="col-xs-4 Social-icon">
                <SocialIcon url="https://github.com/NilssonMandola" network="github" bgColor="#102e4e" fgColor="#e8eef2" target="_blank"/>
            </div>
            <div className="col-xs-4 Social-icon">
                <SocialIcon url="mailto:ola@nilsonium.com" network="email" bgColor="#102e4e" fgColor="#e8eef2" target="_blank"/>
            </div>
        </div>
    );
}

export default Social;
