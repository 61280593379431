import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div className="container text-center">
                <p>Copyright © {new Date(Date.now()).getFullYear()} Nilsonium, All Rights Reserved</p>
            </div>
        </footer>
    );
}

export default Footer;

