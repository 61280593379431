import React from 'react';
import wordmark_logo from '../nilsonium_logo_wordmark_clean.png';
import Navbar from 'react-bootstrap/Navbar';

function MyNavbar() {
    return (
        <Navbar className="navbar-custom-background fixed-top">
            <Navbar.Brand href="/">
                    <img alt="" src={wordmark_logo} height="23" className="d-inline-block align-top" />
            </Navbar.Brand>
        </Navbar>
    );
}

export default MyNavbar;