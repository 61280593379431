import React from 'react';
import MyNavbar from './components/navbar';
import Social from './components/social';
import Particles from './components/particles';
import Footer from './components/footer';
import MainLogo from './components/mainlogo';
import './App.css';

function App() {

  return (
    <div className="App fade-in">
      <Particles />
      <MyNavbar />
      <div className="Main-content" >
        <div className='col-12'>
          <MainLogo />
        </div>
        <div className='col-12 d-flex justify-content-center'>
          <Social />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
